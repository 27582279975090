import React from 'react';

export class ExistingProductSelected extends React.Component {
    getFormattedDownloadRange(product) {
        if (product.minDownloadSpeed === product.maxDownloadSpeed) {
            return product.maxDownloadSpeed + " Mbps download";
        }
        return "Between " + product.minDownloadSpeed + " and " + product.maxDownloadSpeed + " Mbps download";
    }

    getFormattedUploadRange(product) {
        if (product.minUploadSpeed === product.maxUploadSpeed) {
            return product.maxUploadSpeed + " Mbps upload";
        }
        return "Between " + product.minUploadSpeed + " and " + product.maxUploadSpeed + " Mbps upload";
    }
    render() {
        if (this.props.selectedProduct !== null) {
            return (
                <div className="columns is-mobile is-centered is-vcentered">
                    <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                        <div className="card selected">
                            <div className="card-header">
                                <div className="card-header-title is-centered">
                                <h2 className="subtitle is-5">Current Service</h2>
                                </div>
                            </div>
                            <div className="card-content">
                                <p className="block">{this.props.selectedProduct.productName}</p>
                                <p className="block">
                                    {this.props.selectedProduct.minDownloadSpeed !== null ? this.getFormattedDownloadRange(this.props.selectedProduct) : this.props.selectedProduct.maxDownloadSpeed + " Mbps download"}
                                    <br />
                                    {this.props.selectedProduct.minUploadSpeed !== null ? this.getFormattedUploadRange(this.props.selectedProduct) : this.props.selectedProduct.maxUploadSpeed + " Mbps upload"}
                                </p>
                                <p className="block">{this.props.selectedProduct.term} month minimum term</p>
                                <p>£{this.props.selectedProduct.monthlyFee.toFixed(2)} per month</p>
                                {
                                    this.props.selectedProduct.setupFee.toFixed(2) !== "0.00" && this.props.showSetupFee &&
                                    <p>£{this.props.selectedProduct.setupFee.toFixed(2)} setup fee</p>
                                }
                                {
                                    this.props.allowChange &&
                                    <button className="button is-text has-text-link" onClick={this.props.onClick}>Change</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else return null;
    }
}