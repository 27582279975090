class RouterHelper {

    static getDiscountedRouterPrice(router, routerDiscount) {
        var routerPrice = router.price.toFixed(2) - routerDiscount;

        if (routerPrice < 0) {
            routerPrice = 0;
        }

        return "£" + routerPrice.toFixed(2);
    }

    static getRouterDiscountInfo(router, routerDiscount, discountExpiry, discountCode) {
        if (router.price == 0 || routerDiscount === undefined) {
            return "";
        }
        
        return (
            <div className='mt-5'>
                <p className='bold'>SPECIAL OFFER:</p>
                <p>{this.getDiscountedRouterPrice(router, routerDiscount)}</p>
                {this.getRouterDiscountExpiry(routerDiscount, discountExpiry, discountCode)}
            </div>
        );
    }

    static getRouterDiscountExpiry(routerDiscount, discountExpiry, discountCode) {
        if (routerDiscount > 0 && discountExpiry != "") {
            return (<p>Offer ends {discountExpiry}<br /><span className="bold">Use discount code: {discountCode}</span></p>);
        }
        
        return "";
    }
}

export default RouterHelper;