import './App.scss';
import { SignUp } from './components/SignUp';
import { ServiceUpgrade } from './components/Upgrade/ServiceUpgrade';
import { OrderConfirmation } from './components/Confirmation/OrderConfirmation';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { rollbarConfig } from './components/Rollbar.js'
import { ServiceRenewal } from './components/Renew/ServiceRenewal';
import { PortRequestDetails } from './components/PortRequest/PortRequestDetails';

function App() {
  var maintenance = false;
  if (maintenance) {
    return (
        <div className="App m-5">
          <div className="columns is-mobile is-centered is-vcentered">
            <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
              <h1 className="title is-3 mt-3">Website Maintenance</h1>
              <p className="block">Sorry, our website us currently undergoing routine maintenance, please check back later.</p>
            </div>
          </div>
        </div>
    );
  }
  else
  {
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <Router>
            <Switch>
              <Route path='/confirmation'>
                <OrderConfirmation />
              </Route>
              <Route path='/number-port'>
                <PortRequestDetails />
              </Route>
              <Route path='/upgrade/:upgradeCode' component={ServiceUpgrade} />
              <Route path='/upgrade'>
                <ServiceUpgrade />
              </Route>
              <Route path='/renew/:renewalCode' component={ServiceRenewal} />
              <Route path='/renew'>
                <ServiceRenewal />
              </Route>
              <Route path='/refer/:referralCode' component={SignUp} />
              <Route path='/business'>
                <SignUp business={true} />
              </Route>
              <Route path='/'>
                <SignUp />
              </Route>
            </Switch>
          </Router>
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default App;
