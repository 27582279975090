import React from 'react';
import ProductHelper from "./ProductHelper";

export class SelectedProduct extends React.Component {

    render() {
        if (this.props.selectedProduct !== null) {
            return (
                <div className="columns is-mobile is-centered is-vcentered">
                    <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                        <div className="card selected">
                            <div className="card-header">
                                <div className="card-header-title is-centered">
                                <h2 className="subtitle is-5">Selected Service</h2>
                                </div>
                            </div>
                            <div className="card-content">
                                <p className="block">{this.props.selectedProduct.productName}</p>
                                <p className="block">
                                    {this.props.selectedProduct.minDownloadSpeed !== null ? ProductHelper.getFormattedDownloadRange(this.props.selectedProduct) : this.props.selectedProduct.maxDownloadSpeed + " Mbps download"}
                                    <br />
                                    {this.props.selectedProduct.minUploadSpeed !== null ? ProductHelper.getFormattedUploadRange(this.props.selectedProduct) : this.props.selectedProduct.maxUploadSpeed + " Mbps upload"}
                                </p>
                                <p className="block">{ProductHelper.getProductTerm(this.props.selectedProduct)}</p>
                                {ProductHelper.getMonthlyFee(this.props.selectedProduct)}
                                {this.props.showSetupFee ? ProductHelper.getSetupFee(this.props.selectedProduct) : ""}
                                {ProductHelper.getOfferText(this.props.selectedProduct)}
                                <button className="button is-text has-text-link" onClick={this.props.onClick}>Change</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else return null;
    }
}