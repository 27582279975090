import React from 'react';
import { CustomerEmail } from './CustomerEmail';
import { RenewalSelection } from './RenewalSelection';

export class ServiceRenewal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            renewalCode: ''
        }
    }

    async componentDidMount() {
        if (this.props.match?.params.renewalCode) {
            this.setState({
                step: 2,
                renewalCode: this.props.match?.params.renewalCode
            });
        }
    }

    render() {
        return (
            <div className="App m-5">
                <h1 className="title is-3 mt-3 mb-3">Service Renewal</h1>
                {
                    this.state.step === 1 && <CustomerEmail />
                }
                {
                    this.state.step === 2 && <RenewalSelection renewalCode={this.state.renewalCode} />
                }
            </div>
        )
    }
}