class ProductHelper {

    static getOfferText(product) {
        if (product.availableDiscount != null) {
            return (
                <div className='mt-5'>
                    <p className='bold'>SPECIAL OFFER:</p>
                    {this.getDiscountedMonthlyFee(product)}
                    {this.getDiscountedSetupFee(product)}
                    {this.getRouterDiscount(product)}
                    {this.getDiscountMinTerm(product)}
                    {this.getDiscountExpiry(product)}
                </div>
            );
        }
    }

    static getDiscountExpiry(product) {
        if (product.availableDiscount != null) {
            if (product.availableDiscount.expiryDate != '') {
                return (
                    <p>Offer ends {product.availableDiscount.expiryDate}<br /><span className="bold">Use discount code: {product.availableDiscount.discountCode}</span></p>
                );
            }
        }
    }

    static getDiscountedMonthlyFee(product) {
        if (product.availableDiscount != null) {
            if (product.availableDiscount.monthlyDiscountGbp > 0) {
                var discountedMonthlyFee = product.monthlyFee.toFixed(2) - product.availableDiscount.monthlyDiscountGbp.toFixed(2);
                if (discountedMonthlyFee < 0) {
                    discountedMonthlyFee = 0;
                }
                var discountDurationText = "";
                if (product.availableDiscount.monthlyDiscountDuration > 0) {
                    discountDurationText = " for " + product.availableDiscount.monthlyDiscountDuration + " months";
                }
                return (
                    <p>£{discountedMonthlyFee.toFixed(2)} per month{discountDurationText}</p>
                );
            }
        }
    }

    static getDiscountMinTerm(product) {
        if (product.availableDiscount != null && product.availableDiscount.minimumTerm != null
            && product.availableDiscount.minimumTerm > 0 && product.availableDiscount.minimumTerm != product.term) {
            return product.availableDiscount.minimumTerm + " month minimum term";
        }
    }

    static getDiscountedSetupFee(product) {
        if (product.availableDiscount != null) {
            if (product.availableDiscount.setupDiscountGbp > 0) {
                var discountedSetupFee = product.setupFee.toFixed(2) - product.availableDiscount.setupDiscountGbp.toFixed(2);
                if (discountedSetupFee < 0) {
                    discountedSetupFee = 0;
                }
                return (
                    <p>£{discountedSetupFee.toFixed(2)} setup fee</p>
                );
            }
        }
    }

    static getRouterDiscount(product) {
        if (product.availableDiscount != null) {
            if (product.availableDiscount.routerDiscountGbp > 0) {
                return (
                    <p>Up to £{product.availableDiscount.routerDiscountGbp.toFixed(2)} router discount</p>
                );
            }
        }
    }

    static getMonthlyFee(product) {
        return (<p>£{product.monthlyFee.toFixed(2)} per month</p>);
    }

    static getSetupFee(product) {
        return (<p>£{product.setupFee.toFixed(2)} setup fee</p>);
    }

    static getFormattedDownloadRange(product) {
        if (product.minDownloadSpeed === product.maxDownloadSpeed) {
            return product.maxDownloadSpeed + " Mbps download";
        }
        return "Between " + product.minDownloadSpeed + " and " + product.maxDownloadSpeed + " Mbps download";
    }

    static getFormattedUploadRange(product) {
        if (product.minUploadSpeed === product.maxUploadSpeed) {
            return product.maxUploadSpeed + " Mbps upload";
        }
        return "Between " + product.minUploadSpeed + " and " + product.maxUploadSpeed + " Mbps upload";
    }

    static getProductTerm(product) {
        return product.term + " month minimum term";
    }

}

export default ProductHelper;