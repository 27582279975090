import React from 'react';
import { PostcodeSearch } from './PostcodeSearch';
import { AddressSelector } from './AddressSelector';

export class AddressDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            postcode: this.props.postcode,
            selectedAddress: this.props.selectedAddress,
            useUprn: true
        };
        
        this.handlePostcodeClick = this.handlePostcodeClick.bind(this);
        this.handleAddressClick = this.handleAddressClick.bind(this);
        this.handleMissingAddressClick = this.handleMissingAddressClick.bind(this);
        this.addressListLoaded = this.addressListLoaded.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    addressListLoaded(addressList) {
        if (addressList !== null && addressList !== undefined && addressList.length > 0) {
            if (this.state.useUprn) {
                document.getElementById("missingAddressCta").classList.remove("is-hidden");
            }
            else {
                document.getElementById("missingOpenreachAddressCta").classList.remove("is-hidden");
            }
        }
    }

    handlePostcodeClick(newPostcode) {
        document.getElementById("missingAddressCta").classList.add("is-hidden");
        document.getElementById("missingOpenreachAddressCta").classList.add("is-hidden");
        this.setState({ postcode: '', useUprn: true },
        function() {
            this.setState({ postcode: newPostcode });
        });
    }

    handleMissingAddressClick() {
        this.setState({ useUprn: false });
        var cta = document.getElementById("missingAddressCta");
        if (cta) {
            cta.classList.add("is-hidden");
        }
    }

    handleAddressClick(newSelectedAddress) {
        this.setState({ selectedAddress: newSelectedAddress });
        this.props.onClick(newSelectedAddress);
    }

    handleReset() {
        this.setState({ postcode: '', selectedAddress: null });
        this.props.onReset();
    }

    render() { 
        if (this.state.selectedAddress === null || this.state.selectedAddress === undefined) {
            return (
                <div className="columns is-mobile is-centered is-vcentered">
                    <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                        <h1 className="title is-3 mt-3">Address Details</h1>
                        <p className="block">Please enter the full UK postcode for the property where you would like service</p>
                        <div className="box mb-3">
                            <PostcodeSearch onClick={this.handlePostcodeClick} />
                            <div className="block">
                                <p>
                                    <a id="missingAddressCta" className="has-text-link is-hidden my-1" onClick={this.handleMissingAddressClick}>Address not listed?</a>
                                    <a id="missingOpenreachAddressCta" className="has-text-link is-hidden my-1" target="_blank" rel="noreferrer" href="https://1310.io/contact">Address still not listed?</a>
                                </p>
                            </div>
                            <AddressSelector
                                postcode={this.state.postcode}
                                useUprn={this.state.useUprn}
                                onClick={this.handleAddressClick}
                                addressListLoaded={this.addressListLoaded} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return(
                <div className="columns is-mobile is-centered is-vcentered">
                    <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                        <div className="card selected">
                            <div className="card-header">
                                <div className="card-header-title is-centered">
                                <h2 className="subtitle is-5">Selected Address</h2>
                                </div>
                            </div>
                            <div className="card-content">
                                <p>
                                    {this.state.selectedAddress.formattedAddress}
                                </p>
                                <button className="button is-text has-text-link" onClick={this.handleReset}>Change</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}