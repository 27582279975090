import React from 'react';
import { AddressDetails } from './Address/AddressDetails';
import { ProductDetails } from './Product/ProductDetails';
import { CustomerDetails } from './CustomerDetails';
import { CompanyDetails } from './CompanyDetails';

export class SignUp extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            step: 1,
            selectedAddress: null,
            availabilitySummary: null,
            selectedProduct: null,
            selectedRouter: null,
            customerDetails: null,
            business: this.props.business !== undefined ? this.props.business : false,
            referralCode: this.props.match?.params.referralCode
        };

        this.handleAddressClick = this.handleAddressClick.bind(this);
        this.handleAddressReset = this.handleAddressReset.bind(this);
        this.handleProductClick = this.handleProductClick.bind(this);
        this.handleBusinessClick = this.handleBusinessClick.bind(this);
        this.handleResidentialClick = this.handleResidentialClick.bind(this);
    }

    handleBusinessClick() {
        this.setState({
            business: true
        });
    }

    handleResidentialClick() {
        this.setState({
            business: false
        });
    }

    handleAddressClick(newSelectedAddress) {
        this.setState({
            step: 2,
            selectedAddress: newSelectedAddress,
            availabilitySummary: null,
            selectedProductId: 0,
            selectedRouterId: 0,
            localPopId: 0,
            locationId: 0
        });
    }

    handleAddressReset() {
        this.setState({
            step: 1,
            selectedAddress: null,
            availabilitySummary: null,
            selectedProduct: null,
            selectedRouter: null,
        });
    }

    handleProductClick(newSelectedProduct, newSelectedRouter, routerSelected = false) {
        this.setState({
            selectedProduct: newSelectedProduct, 
            selectedRouter: newSelectedRouter,
            step: routerSelected ? 3 : 2,
            business: newSelectedProduct == null ? false : newSelectedProduct.businessOnly });
    }

    render() {
        return (
            <div className="App m-5">
                {
                    this.state.step >= 1 && 
                    <AddressDetails onClick={this.handleAddressClick} onReset={this.handleAddressReset} />
                }
                {
                    this.state.step >= 2 && 
                    <ProductDetails selectedAddress={this.state.selectedAddress} onClick={this.handleProductClick} business={this.state.business}
                        showSetupFee={true} discountCode={this.state.discountCode} />
                }
                {
                    this.state.step >= 3 && !this.state.business &&
                    <CustomerDetails selectedProduct={this.state.selectedProduct} selectedRouter={this.state.selectedRouter} 
                        selectedAddress={this.state.selectedAddress} referralCode={this.state.referralCode} 
                        onBusinessClick={this.handleBusinessClick} showSetupFee={true} />
                }
                {
                    this.state.step >= 3 && this.state.business &&
                    <CompanyDetails selectedProduct={this.state.selectedProduct} selectedRouter={this.state.selectedRouter} 
                        selectedAddress={this.state.selectedAddress} referralCode={this.state.referralCode}
                        onResidentialClick={this.handleResidentialClick} showSetupFee={true} />
                }
            </div>
        )
    }
}