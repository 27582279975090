import React from 'react';
import axios from 'axios';
import { rollbar } from '../Rollbar.js'

export class WaitlistSignUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                name: '',
                phone: '',
                email: '',
                address: this.props.selectedAddress.formattedAddress,
                uprn: this.props.selectedAddress.uprn,
                latitude: this.props.selectedAddress.latitude,
                longitude: this.props.selectedAddress.longitude,
            },
            errors: {
                name: '',
                phone: '',
                email: '',
                generic: false
            },
            loading: false,
            error: false,
            complete: false
        };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);

        this.handleValidation = this.handleValidation.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleHomeClick = this.handleHomeClick.bind(this);
    }

    handleHomeClick(e) {
        window.location.href = "https://1310.io/";
    }

    handleNameChange(e) {
        const newName = e.target.value;
        let fields = this.state.fields;
        fields["name"] = newName;
        this.setState({ fields });
    }

    handlePhoneChange(e) {
        const newPhoneNumber = e.target.value;
        let fields = this.state.fields;
        fields["phone"] = newPhoneNumber;
        this.setState({ fields });
    }

    handleEmailChange(e) {
        const newEmail = e.target.value;
        let fields = this.state.fields;
        fields["email"] = newEmail;
        this.setState({ fields });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;

        if(!fields["name"]){
           formIsValid = false;
           errors["name"] = "Please enter your name";
        } else {
            errors["name"] = "";
        }
        
        if(!fields["phone"] && !fields["email"]){
            formIsValid = false;
            errors["phone"] = "Please enter your mobile number";
            errors["email"] = "Please enter your email address";
        } else {
            errors["phone"] = "";
            errors["email"] = "";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    async handleSubmitClick() {
        if (this.handleValidation()) {
            this.setState({ loading: true });
            if (process.env.NODE_ENV === "production") {
                window.gtag('event', 'waitlist_signup');
            }
            else {
                window.gtag('event', 'waitlist_signup', { 'debug_mode':true });
            }
            await axios.post(process.env.REACT_APP_THIRTEEN_TEN_API + '/customer/waitlist', this.state.fields)
            .then(() => { this.setState({ complete: true }); } )
            .catch(error => {
                rollbar.error(error);
                this.handleGenericError();
            })
            .finally(() => {
                this.setState({ loading: false });
            });
        }
    }

    handleGenericError() {
        let errors = this.state.errors;
        errors.generic = true;
        this.setState({errors});
    }

    render() {
        return (
            <div className="block">
                {
                    !this.state.complete &&
                    <div>
                        <p>
                            Unfortunately, we are not yet able to provide service in your area.
                            <br />
                            Please fill out the form below to be notified as soon as service is available:
                        </p>
                        <div id="customerDetails" className="columns is-mobile is-centered is-vcentered mt-3">
                            <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                                <div className="block">
                                    <div className="box">
                                        <div className="field">
                                            <div className="control is-expanded">
                                                {this.state.errors.name.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.name}</p>}
                                                <input className={`input ${this.state.errors.name.length > 0 ? "is-danger" : ""}`} placeholder="Name" id="first_name" type="text" value={this.state.fields.name} onChange={this.handleNameChange} />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="control is-expanded">
                                                {this.state.errors.phone.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.phone}</p>}
                                                <input className={`input ${this.state.errors.phone.length > 0 ? "is-danger" : ""}`} placeholder="Mobile number" id="phone" type="text" value={this.state.fields.phone} onChange={this.handlePhoneChange} />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="control is-expanded">
                                                {this.state.errors.email.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.email}</p>}
                                                <input className={`input ${this.state.errors.email.length > 0 ? "is-danger" : ""}`} placeholder="Email address" id="email" type="text" value={this.state.fields.email} onChange={this.handleEmailChange} />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="control">
                                                {this.state.errors.generic && <p className='has-text-centered mb-2'>Sorry, an error occurred while submitting your details.<br />Please try again.<br />Alternatively, you can <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a><br />or call us on <a href="tel:03333001310">0333 300 1310</a>.</p>}
                                                <button className="button is-primary" onClick={this.handleSubmitClick} disabled={(this.state.loading)}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.loading &&
                                    <div className="fa-2x">
                                        <i className="fas fa-spinner fa-pulse"></i>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.complete &&
                    <div className="columns is-mobile is-centered is-vcentered">
                        <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                            <div id="system-confirmation" className="card selected">
                                <div className="card-header">
                                    <div className="card-header-title is-centered">
                                        <h2 className="subtitle is-5">Waitlist Confirmation</h2>
                                    </div>
                                </div>
                                <div className="card-content">
                                    <h1 className="title is-4 mt-3">Thank you for joining our waitlist</h1>
                                    <p className="m-5">We will be in touch as soon as service is available in your area.</p>
                                    <button className="button is-primary mb-3" onClick={this.handleHomeClick}>Home</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}