import React from 'react';
import RouterHelper from "./RouterHelper";

export class SelectedRouter extends React.Component {

    render() {
        if (this.props.selectedRouterId > -1) {
            return (
                <div className="columns is-mobile is-centered is-vcentered">
                    <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                        <div className="card selected">
                            <div className="card-header">
                                <div className="card-header-title is-centered">
                                <h2 className="subtitle is-5">Selected Router</h2>
                                </div>
                            </div>
                            <div className="card-content">
                                {this.props.selectedRouter !== null && 
                                <div><p>{this.props.selectedRouter.name}</p>
                                    <p>£{this.props.selectedRouter.price.toFixed(2)}</p>
                                    {RouterHelper.getRouterDiscountInfo(this.props.selectedRouter, this.props.routerDiscount, this.props.discountExpiry, this.props.discountCode)}
                                </div>}
                                {this.props.selectedRouter === null && <p>I will supply my own router</p>}
                                <button className="button is-text has-text-link" onClick={this.props.onClick}>Change</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else return null;
    }
}